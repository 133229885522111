.tabellaInterattiva {
    text-align: left;
    font-weight: normal;
    position: relative;

    th,
    td {
        min-width: 200px;
        position: relative;
        border-right: 1px solid rgba(0, 0, 0, 0.1)
    }

    thead {
        th {
            padding: 10px;
            border-right: 1px solid rgb(100, 119, 138);
        }
    }

    .formModificaCella {
        border: none;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
    }

    .rigaTabellaInterattiva {
        transition-duration: 0.3s;
        &:hover {
            background-color: rgba(0,0,0,0.2);
        }
    }

    .cellaAzioniRiga {
        min-width: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 8px;
        padding: 10px;
        gap: 4px;

        .callToActionRiga {
            padding: 8px;
            cursor: pointer;
            transition-duration: 0.2s;
            border-radius: 4px;

            &:hover {
                filter: brightness(1.2);
            }
        }

    }

    thead {
        position: sticky;
        top: 0;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    }

    tbody {
        font-weight: lighter;

        tr:nth-child(even) {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}